class RatHelper {
    static getAppearance(rat) {
        const appearanceParts = [];
    
    // Add color
    if (rat.color) {
        appearanceParts.push(rat.color);
    }
    
    // Add markings if present
    if (rat.markings && rat.markings !== "") {
        appearanceParts.push(rat.markings);
    }
    
    // Add fur type only if not "Slät"
    if (rat.furType && rat.furType.toLowerCase() !== "slät") {
        appearanceParts.push(rat.furType);
    }
    
    // Add ear type according to rules
    if (rat.earType) {
        const earType = rat.earType.toLowerCase();
        if (earType === "dumboöra") {
            appearanceParts.push("du");
        } else if (earType === "dumbobärare") {
            appearanceParts.push("db");
        }
        // "Toppöra" is intentionally omitted
    }
    
    return appearanceParts.join(" ");
    }
  }
  
  export default RatHelper;